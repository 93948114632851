import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFontAwesome,faTwitter,faGithub,faLinkedinIn  } from '@fortawesome/free-brands-svg-icons'

import { faCoffee,faPuzzlePiece, faBookOpen } from '@fortawesome/free-solid-svg-icons'

library.add(faCoffee)
library.add(faFontAwesome)
library.add(faTwitter)
library.add(faGithub)
library.add(faLinkedinIn)
library.add(faPuzzlePiece)
library.add(faBookOpen)



Vue.component('font-awesome-icon', FontAwesomeIcon)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
