<template>
<div>
    <b-jumbotron class="bam">

        <template #header>Learning through Play</template>

        <template #lead>
            An open platform for AI Education and Research
        </template>

    </b-jumbotron>
        <div style="height:100px">
</div>
    <b-container fluid class="m-5">
        <b-row class="text-center">
            <b-col>
                <b-card title="Accessibility">
                    <b-card-text>
                        Explore and use reinforcement learning algorithms regardless of your skill level. Coding optional.
                    </b-card-text>
                </b-card>

            </b-col>
            <b-col>
                <b-card title="Control">
                    <b-card-text>
                        Experiment using open source software on your own hardware without relying on proprietary services.
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col>

                <b-card title="Collaboration">
                    <b-card-text>
                        Building a community hub for sharing agents, algorithms, and environments.
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row >

                <!-- <b-row class="text-center mt-5">
            <b-col>
                <b-card title="Learn">
                    <b-card-text>
                        Dive deep 
                    </b-card-text>
                </b-card>

            </b-col>
            <b-col>
                <b-card title="Build">
                    <b-card-text>
                        Develop extension
                    </b-card-text>
                </b-card>

            </b-col>
            <b-col>

                <b-card title="Collaboration">
                    <b-card-text>
                        Build on the work of others and share your own insights.
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row> -->
        <!-- <b-row class="text-center">
            <b-col>
                <b-card title="Intuition">
                    <b-card-text>
                        Learn through play and exploration
                    </b-card-text>
                </b-card>

            </b-col>

            <b-col>

                <b-card title="Collaboration">
                    <b-card-text>
                        Build on the development of others and share
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row> -->
    </b-container>

</div>
</template>

<script>
export default {
    name: 'Home',
}
</script>
